.main {
    min-height: 100vh;
    /* background-image: linear-gradient(to bottom right, rgba(0, 0, 0, 0.219), rgba(0, 0, 0, 0.281)), url("./contactbg.png"), linear-gradient(to bottom right, #000, #363636); */
    backdrop-filter: brightess(20%);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    font-family: Space Grotesk;
}

.heading {
    font-family: SF Distant Galaxy;
    font-style: normal;
    font-weight: normal;
    color: #FFFFFF;
    text-align: center;
    font-size: 2.5vw;
    z-index: 100;
    margin-top: 3vh;
}

.desc {
    margin-top: 1vh;
    text-align: center;
    width: 50%;
    color: #FFFFFF;
    margin-left: auto;
    margin-right: auto;
}

.orangelink {
    color: #EF6522;
    cursor: pointer;
}

.orangelink:hover {
    color: #ca551a;
}

@media (max-width:1000px) {
    .heading {
        font-size: 6.5vw;
        z-index: 100;
        margin-top: 3vh;
    }

    .desc {
        margin-top: 1vh;
        text-align: center;
        width: 80%;
        font-size: 12px;
        color: #FFFFFF;
        margin-left: auto;
        margin-right: auto;
    }
}