@font-face {
  font-family: "galaxy";
  font-weight: 400;
  src: url("../../Assets/Fonts/SF\ Distant\ Galaxy.ttf");
}

/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap"); */

.container {
  /* background: linear-gradient(295.53deg, #363636 -6.16%, #000000 119.91%); */
}

.edition {
  font-family: "galaxy";
}

.editionYear {
  font-family: "Open Sans", sans-serif;
}

.Content {
  font-family: "Open Sans", sans-serif;
}

.circle {
  border-radius: 100%;
  border: 1px solid white;

  background: transparent;
}

.past {
  background: rgba(255, 255, 255, 0.098);
  box-shadow: inset 30.0667px -30.0667px 30.0667px rgba(182, 185, 182, 0.098),
    inset -30.0667px 30.0667px 30.0667px rgba(255, 255, 255, 0.098);
  backdrop-filter: blur(102.828px);
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-image: linear-gradient(
      90deg,
      rgba(239, 101, 34, 0.59),
      rgba(255, 255, 255, 0.19)
    )
    1;
}

@media screen and (max-width: 940px) {
  .carImg2 {
    display: none;
  }
}
@media screen and (max-width: 912px) {
  .xsm {
    display: none;
  }
}
@media screen and (min-width: 540px) and (max-width: 599px) {
  .carImg {
    position: absolute;
    top: 19vh;
  }

  .firstDiv {
    display: flex;
    gap: 40vh;
  }

  #carImg2 {
    position: relative;
    bottom: 30vh;
  }

  .circle {
    height: 24vh;
  }

  .arrow {
    position: relative;
    font-size: 24vh;
  }
  .pastMainDiv {
    display: flex;
    gap: 6vw;
  }

  .past {
    width: 220px;
    height: 120px;
  }

  #pastVideo {
    width: 200px;
    height: 100px;
  }
}

@media screen and (min-width: 912px) and (max-width: 1023px) {
  .carImg {
    position: absolute;
    top: 20vh;
    left: 9vh;
  }
}

@media screen and (min-width: 600px) and (max-width: 912px) {
  #pastVideo {
    width: 280px;
    height: 150px;
  }
}

@media screen and (min-width: 1024px) {
  #pastVideo {
    width: 400px;
    height: 200px;
  }
}
