/* .container {
  background: linear-gradient(295.53deg, #363636 -6.16%, #000000 119.91%);

} */
.circle {
  border-radius: 100%;
  border: 1px solid white;

  background: transparent;
}

@font-face {
  font-family: "galaxy";
  font-weight: 400;
  src: url("../Fonts/SF\ Distant\ Galaxy.ttf");
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

.edition {
  font-family: "galaxy";
}

.Content {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

.carImg2 {
  backdrop-filter: drop-shadow(0px -37px 80px rgba(0, 0, 0, 0.07))
    drop-shadow(0px -15.4577px 33.4221px rgba(0, 0, 0, 0.0503198))
    drop-shadow(0px -8.26443px 17.869px rgba(0, 0, 0, 0.0417275))
    drop-shadow(0px -4.63297px 10.0172px rgba(0, 0, 0, 0.035))
    drop-shadow(0px -2.46054px 5.32008px rgba(0, 0, 0, 0.0282725))
    drop-shadow(0px -1.02388px 2.21381px rgba(0, 0, 0, 0.0196802));
}
.reveal {
  background: transparent;
  width: 15%;
  color: #fff;
  font-size: 20px;
}

@media screen and (max-width: 1300px) {
  .xsm {
    display: none;
  }
}
