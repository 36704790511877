.patrons_container {
  background-image: url("../../../Assets/About/left.png"),
    url("../../../Assets/About/right.png");
  background-size: contain;
  width: 500px, 500px;
  /* background-position: left bottom, right top; */
  background-position: right bottom, top left;

  background-repeat: no-repeat;
  /* opacity: .5; */
  background-size: 300px;
  z-index: 0;
}

@media (max-width: 768px) {
  .patrons_container {
    background-image: url("../../../Assets/About/left_mob.svg"),
      url("../../../Assets/About/right_mob.svg");
    background-size: contain;
    background-position: top left, right bottom;
    background-size: 200px;
    z-index: 0;
  }
}
