.legacy {
    background-image: url("../../Assets/About/legacy.svg");
    background-size: 30%;
    /* background-repeat: space; */
}

/* .legacydesc {
    background-image: url("../Assets//Images/legacy.svg");
    background-size: 70%;
    background-position: top left;
} */

.legacytext::-webkit-scrollbar {
  display: block;
}
.legacytext::-webkit-scrollbar-corner {
  background-color:transparent !important;
}
.legacytext::-webkit-scrollbar-track {
  background-color:none;
}
.legacytext::-webkit-scrollbar-thumb {
  background-color:white;
}


@media (max-width:768px) {
    .legacy {
        background-size: 50%;
    }

    .legacydesc {
        background-size: 50%;
    }
}