@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@900&family=Open+Sans:wght@300&family=Montserrat:wght@400&family=Space+Grotesk:wght@300&display=swap');
@import url('https://fonts.cdnfonts.com/css/messina');

.sg {
    font-family: Space Grotesk;
    font-size: 1.2rem;
}

.navsg {
    font-family: Space Grotesk;
}