.eventCardBg {
  background: linear-gradient(147.37deg, #363636 -20.04%, #000000 135.5%);
}

.gwrCardBg {
  background: linear-gradient(147.37deg,
      rgba(54, 54, 54, 0.39) -20.04%,
      rgba(0, 0, 0, 0.39) 135.5%);
}

.ecorun {
  background-image: url(./ecorunbg.svg);
}

.gwr {
  background-image: url(./gwr2.png);
  background-size: cover;
  background-position: center;
}

.circle {
  border-radius: 100%;
  border: 1px solid #ef6522;
  background: transparent;
}

.EduCardBg {
  background: rgba(255, 255, 255, 0.098);
  box-shadow: inset 18.9948px -18.9948px 18.9948px rgba(182, 185, 182, 0.098),
    inset -18.9948px 18.9948px 18.9948px rgba(255, 255, 255, 0.098);
  backdrop-filter: blur(64.9621px);
}

.EduMainDiv {
  background: linear-gradient(258.7deg, #8a59ef -24.41%, #5563ee 114.45%);
}

.EduContainDiv {
  background-image: url("./ini_bg.svg");
  background-repeat: no-repeat;
  /* background-position: 50% -30%; */
  background-size: contain;
  position: relative;
  bottom: 3vh;
}