@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@900&family=Open+Sans:wght@300&family=Montserrat:wght@400&family=Space+Grotesk:wght@300&display=swap');
@import url('https://fonts.cdnfonts.com/css/messina');

.sg {
    font-family: Space Grotesk;
    font-size: 1.2rem;
}

.initiatives {
    font-family: SF Distant Galaxy !important;
    font-style: normal !important;
    font-weight: normal !important;
    color: #FFFFFF !important;
    z-index: 100 !important;
    font-size: 2rem;
    -webkit-text-stroke-width: 0px !important;
    margin-bottom: 0.8rem;
}

.datahead {
    font-family: SF Distant Galaxy !important;
    font-style: normal !important;
    font-weight: normal !important;
    color: #FFFFFF !important;
    z-index: 100 !important;
    font-size: 2.5vh;
    -webkit-text-stroke-width: 0px !important;
    margin-bottom: 0.8rem;
}

.datahead::before {
    content: '•';
    margin-right: 10px;
    font-size: 3vh;
    color: #EF6522;
}

.datafield {
    font-family: Space Grotesk;
    font-size: 1rem;
}

.datafield::before {
    content: ' ';
    margin-right: 12px;
    margin-left: 2px;
    font-size: 3vh;


}

.subhead {
    font-family: Space Grotesk;
    font-style: normal;
    font-weight: bold;
    color: #fff;
    font-size: 3vh;
}





@media (max-width:1024px) {
    .initiatives {
        font-size: 6vh;
    }

    .datahead {
        font-size: 2.5vh;
    }

    .datahead::before {
        content: '•';
        margin-right: 10px;
        font-size: 3vh;
        color: #EF6522;
    }

    .datafield {
        font-size: 0.8rem;
    }

    .datafield::before {
        content: ' ';
        margin-right: 2px;
        margin-left: 0;
        font-size: 0;
    }

}

@media (max-width:768px) {
    .initiatives {
        font-size: 4vh;
    }

}