.legacy {
  text-align: center;
  background-blend-mode: color-dodge;
  font-family: SF Distant Galaxy;
  font-style: normal;
  font-weight: 400;
  font-size: 30vh;
  color: transparent;
  -webkit-text-stroke-width: 0.7px;
  -webkit-text-stroke-color: white;
  z-index: 0;
  opacity: 0.3;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}



@media screen and (max-width: 768px) {
  .legacy {
    font-size: 20vh;
    margin-bottom:10vh;
    white-space: nowrap;
  }
  
}


.About {
  background: radial-gradient(
    ellipse farthest-corner at 10% 10%,
    rgba(73, 35, 16, 0.58) 0%,
    rgba(239, 101, 34, 0) 100%
  );
  opacity: 1;
}
.aboutBg {
  background: radial-gradient(
    50.11% 53.27% at 50.11% 49.89%,
    rgba(239, 101, 34, 0.58) 0%,
    rgba(239, 101, 34, 0) 100%
  );
  opacity: 0.4;
}
.Timelinetxt {
  font-size: 20vh;
}

.timelinesubtxt {
  font-size: 14vh;
}
.circle {
  border-radius: 100%;
  border: 1px solid #ef6522;

  background: transparent;
}

.Event {
  width: 70vw;
  padding: 3vw;
  border: 3.79px solid;
  border-color: linear-gradient(
    rgb(239, 101, 34, 1),
    rgba(235, 235, 235, 0.18)
  );
  border-radius: 0px 37.8676px 37.8676px 0px;
}

.scroll_container {
  overflow: hidden;
}

.scroll_text {
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);

  -moz-animation: my-animation 15s linear infinite;
  -webkit-animation: my-animation 15s linear infinite;
  animation: my-animation 15s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
  }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
