@tailwind base;
@tailwind components;
@tailwind utilities;



@font-face {
  font-family: 'SF Distant Galaxy';
  font-style: normal;
  font-weight: 400;
  src: local('SF Distant Galaxy'), url('./Assets/Fonts/SF Distant Galaxy.ttf') format('truetype');
}

@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 200;
  src: local('Space Grotesk'), url('./Assets/Fonts/SpaceGrotesk.otf') format('opentype');
}



/* @layer base {
  body {
    @apply bg-zinc-800;
  }
}
@font-face {
  font-family: "galaxy";
  src: url("../public/assets15edi/sf-distant-galaxy/TrueType/SFDistantGalaxy.ttf");
}
.galaxyfont {
  font-family: "galaxy";
} */

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  /* background-image: linear-gradient(to bottom right, #000, #363636); */
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

::-moz-selection {
  /* Code for Firefox */
  background: #ef66229a;
}

::selection {
  background: #ef662288;
}

.carousel .control-dots {

  margin-bottom: 0;
}


input:-webkit-autofill,
input:autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus {
  background-color: transparent !important;
  -webkit-text-fill-color: white;
  -webkit-background-composite: transparent;
  transition: background-color 50000000s ease-in-out 0s;
  color: white !important;
  outline: transparent;
  border: 0px;
}

img {
  pointer-events: none;
}