@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400&family=Space+Grotesk:wght@300&display=swap');


.mainhead {
    font-family: SF Distant Galaxy;
    font-style: normal;
    font-weight: normal;
    color: #FFFFFF;
    font-size: 4vw;
}

.sponsorshead {
    font-family: SF Distant Galaxy;
    font-style: normal;
    font-weight: normal;
    color: #FFFFFF;
    font-size: 3vw;
    z-index: 100 !important;
    margin: auto !important;
    text-align: center;
}

.segment {
    background-image: url('../../Assets/abstract.svg');
    background-repeat: no-repeat;
    background-size: 95%;
}

@media (max-width:768px) {
    .segment {
        background-image: url("../../Assets/abstract.svg");
        background-repeat: no-repeat;
        background-size: 0;
    }

    .mainhead {
        font-size: 8vw;
    }

    .sponsorshead {
        font-size: 4vh;
    }
}

.About {
    background-color: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.About::after {
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);
    transition: 0.5s;
    position: absolute;
    left: -100%;
    width: 100%;
    height: 100%;
    content: ' ';
}

.About:hover::after {
    left: 100%;
}