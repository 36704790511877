@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@900&family=Open+Sans:wght@300&family=Montserrat:wght@400&family=Space+Grotesk:wght@300&display=swap');
@import url('https://fonts.cdnfonts.com/css/messina');



.mainSection1 {
    min-height: 75vh !important;
    background-image: url("../../../Assets/liveevents/bgurl1.svg"), url("../../../Assets/liveevents/bgurl2.svg");
    background-position: bottom left, top right;
    background-repeat: no-repeat;
    background-size: 15%, 15%;
}

/* .mainbg {
    background-image: url("../../Assets/Images/liveevents/mainbg.svg");
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: 30%;
} */

.mainhead {
    /* position: static; */
    /* top: 3vw; */
    z-index: 1 !important;
    background-blend-mode: color-dodge;
    font-family: SF Distant Galaxy;
    font-style: normal;
    font-weight: normal;
    font-size: 24vh;
    line-height: 28vh;
    color: transparent;
    margin-left: auto !important;
    -webkit-text-stroke-width: 0.7px;
    -webkit-text-stroke-color: rgba(255, 255, 255, 0.253);
    z-index: 0;
    opacity: 0.9;
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}

.initiatives {
    font-family: SF Distant Galaxy !important;
    font-style: normal !important;
    font-weight: normal !important;
    color: #FFFFFF !important;
    text-align: center !important;
    font-size: 2.5vw !important;
    z-index: 100 !important;
    margin-top: -42vh !important;
    font-size: 8vh;
    -webkit-text-stroke-width: 0px !important;
}

/* .subtext {
    
    top: 45vh;
    left: 10vw;
    transform: rotate(-90deg);
    text-align: center;
    height: 10vw;
} */

.subtextright {
    transform: rotate(-90deg);
    position: absolute;
    right: 0;
    background-blend-mode: color-dodge;
    font-family: Nunito Sans;
    font-style: normal;
    /* font-weight: 900; */
    font-size: 10vh;
    color: transparent;
    margin-left: auto !important;
    -webkit-text-stroke-width: 0.8px;
    -webkit-text-stroke-color: white;
    z-index: 0;
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}

.subtextleft {
    transform: rotate(-90deg);
    position: absolute;
    font-family: Messina Sans;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    color: rgba(243, 243, 243, 1);
    ;
}

.circle {
    width: 4vw;
    margin-left: auto !important;
    margin-right: auto !important;
}

.arrow {
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: -8.5vw;
}

.line {
    position: absolute;
    top: 46vh;
}

.btn {
    color: black;
    background: rgba(255, 255, 255,1);
    box-shadow: 10px 4px 36px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(30px);
    padding: 0.5rem;
    border-radius: 5px;
    position: absolute;
    left: 5vw;
}

.dot {
    height: 25px;
    width: 25px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
}

@media (max-width:810px) {
    .mainSection1 {
        background-image: url("../../../Assets/liveevents/mobleft.svg"), url("../../../Assets/liveevents/mobright.svg"), url("../../../Assets/liveevents/mobdesg.svg");
        background-size: 50%, 20%, 30%;
        background-position: bottom left, right, bottom left;
        min-height: 50vh !important;
    }

    .mainhead {
        left: 9vw;
        padding-top: 3vh;
        margin-top: 8vh;
        font-size: 14vw !important;
        line-height: 11vh;
    }

    .initiatives {
        font-size: 4vh !important;
        padding-top: 25.5vh !important;
    }

    .subtextleft {
        top: 74vh;
        left: -9vw;
        font-size: 13px;
    }

    .subtextright {
        top: 78vh;
        font-size: 4vh;
        top: 5vw;
    }

    .circle {
        width: 14vw;
        margin-left: auto !important;
        margin-right: auto !important;
        transform: translate(0, 10vh);

    }

    .line {
        display: none;
    }
}

/* @media (max-width:768px) and (orientation:landscape) {
    .mainhead {
        left: 9vw;
        top: 18vh;
        font-size: 14vw !important;
    }

    .initiatives {
        font-size: 4vh !important;
        padding-top: 11vh !important;
    }

    .subtextleft {
        top: 74vh;
        left: -9vw;
        font-size: 13px;
    }

    .subtextright {
        top: 78vh;
        font-size: 4vh;
        right: 10vw;
    }

    .circle {
        width: 14vw;
        margin-left: auto !important;
        margin-right: auto !important;
        transform: translate(0, 10vh);

    }

    .arrowgroup {
        margin-left: auto !important;
        margin-right: auto !important;
        bottom: 3vh;
        left: 43vw;
    }

    .arrow {
        margin-left: auto !important;
        margin-right: auto !important;
        padding-bottom: 5vh;
    }
} */

@media (max-width:300px) {
    .subtextleft {
        top: 74vh;
        left: -15vw;
        font-size: 5px;
    }

    .subtextright {
        top: 80vh;
        font-size: 4vh;
        right: 10vw;
    }

    .initiatives {
        padding-top: 13vh !important;
    }

    .circle {
        width: 10vw;
    }
}

@media(max-width:1000px) and (orientation:portrait) {
    .mainhead {
        left: 9vw;
        top: 18vh;
        font-size: 9vh;
    }

    .guitar {
        margin-top: 5vh;
    }

    .initiatives {
        font-size: 4vh !important;
        padding-top: 3.5vh;
    }

    .subtextleft {
        bottom: 20vh;
        font-size: 18px;
    }

    .subtextright {
        bottom: 15.5vh;
        font-size: 6vh;

    }
}