.circle {
  width: 4vw;
  margin-left: auto !important;
  margin-right: auto !important;
  transform: translate(0, 10vh);
}

.arrowprev {
  margin-left: auto !important;
  margin-right: auto !important;
}

.arrowgroupprev {
  position: absolute;
  top: 0vh;
  left: 10vw;
}

.arrowgroupnext {
  position: absolute;
  top: 0vh;
  right: 10vw;
}

@media (max-width: 810px) {
  .arrowgroupprev {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media (max-width: 300px) {
  .circle {
    width: 10vw;
  }

  /* .arrowprev {} */
}

.prevhov:hover {
  animation: prev 1s infinite ease-in-out;
}

.nexthov:hover {
  animation: next 1s infinite ease-in-out;
}

@keyframes prev {
  0% {
    translate-x: 0;
  }

  50% {
    translate-x: -1rem;
  }

  100% {
    translate-x: 0;
  }
}

@keyframes next {
  0% {
    translate-x: 0;
  }

  50% {
    translate-x: 1rem;
  }

  100% {
    translate-x: 0;
  }
}