.heading {
    font-family: SF Distant Galaxy;
    font-style: normal;
    font-weight: normal;
    color: #FFFFFF;
    text-align: left;
    font-size: 4vw;
    z-index: 100;
}

.subhead {
    font-family: Space Grotesk;
    font-style: normal;
    font-weight: 500;
    color: white;
    margin-top: 30px;
    font-size: 1.5vw;
}

@media (max-width:768px) {
    .heading {
        font-size: 8vw;
    }

    .subhead {
        font-size: 3vw;
    }
}