.btn {
  /* background: rgba(255, 255, 255, 0.2); */
  box-shadow: 10px 4px 36px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(30px);
  padding: 0.5rem;
  border-radius: 5px;
  /* position: absolute; */
  /* left:5vw; */
}

.dot {
  height: 25px;
  width: 25px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}

@media (max-width:1023px) {
  .mobileNavbar {
    display: block;

  }
}




.hamburger-menu {
  position: fixed;
  top: 1rem;
  right: 2rem;
  z-index: 10;
  cursor: pointer;
}

.menuBar1,
.menuBar2,
.menuBar3 {
  width: 3.5rem;
  height: 0.2rem;
  background-color: #fff;
  margin: 0.8rem 0;
  transition: 0.4s;
}

.menuBar2 {
  width: 2rem;
  margin-left: auto;
}

/* ROTATE FIRST BAR */
.active .menuBar1 {
  /* transform: rotate(-45deg) translate(-7px, 8px); */
  transform: rotate(-45deg) translate(-0.7rem, 0.8rem);
}

/* FADE OUT SECOND BAR */
.active .menuBar2 {
  opacity: 0;
}

/* ROTATE LAST BAR */
.active .menuBar3 {
  /* transform: rotate(45deg) translate(-6px, -8px); */
  transform: rotate(45deg) translate(-0.6rem, -0.8rem);
}

.unselect {
  user-select: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}