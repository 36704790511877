@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400&family=Space+Grotesk:wght@300&display=swap');



.personname {
    font-family: 'Space Grotesk';
    color: #fff;
}


.persondesc {
    font-family: 'Space Grotesk';
    color: #fff;
}

.heading {
    font-family: SF Distant Galaxy;
    font-style: normal;
    font-weight: normal;
    color: #FFFFFF;
    text-align: center;
    font-size: 2.5vw;
    z-index: 100;
    margin-top: 3vh;
}

.mainhead {
    text-align: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 900;
    font-size: 4vw;
    color: #FFFFFF;
    padding-top: 1vh;
}

.orange {
    color: #EF6522;
}

.legacy {
    position: relative;
    text-align: center;
    margin-bottom: -19vh;
    margin-top: -4vh;
    /* position: absolute; */
    margin-left: auto !important;
    margin-right: auto !important;
    background-blend-mode: color-dodge;
    font-family: SF Distant Galaxy;
    font-style: normal;
    font-weight: normal;
    font-size: 18vh;
    color: transparent;

    -webkit-text-stroke-width: 0.7px;
    -webkit-text-stroke-color: white;
    z-index: 0;
    opacity: 0.2;
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}

.headingdesc {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 2.5vh;
    margin: 2vh 11vw 2vh 11vw;
    text-align: center;
    color: #FFFFFF;

}

.subhead {
    font-family: SF Distant Galaxy;
    font-style: normal;
    font-weight: normal;
    color: #FFFFFF;
    text-align: center;
    font-size: 2vw;
    z-index: 0;
    margin-top: 6vh;
}

.rounded-full:hover {
    display: none !important;
    cursor: pointer !important;
}


.fadeout {
    height: 0;
    overflow: hidden;
    animation: fadeoutAnimation ease-in 5.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.fadein {
    animation: fadeInAnimation ease-in 0.75s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;

    }

    100% {
        opacity: 1;
    }
}


@keyframes fadeoutAnimation {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        height: 0;
        overflow: hidden;
    }
}

@media (max-width:1000px) {
    .legacy {
        font-size: 6.5vh;
        margin-bottom: -10vh;
        margin-top: 0;
    }

    .personname {
        font-weight: bold;
    }

    .persondesc {
        margin-top: 10px;
        font-size: 4vw;
    }

    .heading {
        font-family: SF Distant Galaxy;
        font-style: normal;
        font-weight: normal;
        color: #FFFFFF;
        text-align: center;
        font-size: 6.5vw;
        z-index: 100;
        margin-top: 3vh;
    }

    .mainhead {
        text-align: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 900;
        font-size: 9vw;
        color: #FFFFFF;
        padding-top: 1vh;
    }

    .orange {
        color: #EF6522;
    }

    .headingdesc {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 1.5vh;
        margin: 2vh 11vw 2vh 11vw;
        text-align: center;
        color: #FFFFFF;

    }

    .subhead {
        font-family: SF Distant Galaxy;
        font-style: normal;
        font-weight: normal;
        color: #FFFFFF;
        text-align: center;
        font-size: 5vw;
        z-index: 100;
        margin-top: 6vh;
    }


    /* 
    .descriptionmobile:nth-child(2) {
        display: none;
        right: 8vw;
    } */

    /* .mobileprofile:hover .descriptionmobile {
        display: block;
        transition: 0.3s;
    }*/

    /* .mobileprofile .personname, .mobileprofile .persondesc {
        display: block;
        transition: 0.3s;
    }

    .mobileprofile:hover .personname, .mobileprofile:hover .persondesc {
        display: none;
        transition: 0.3s;
    } */

    /*
    .mobarrow {
        left: 10vw;
    }


    .mobarrow:nth-child(2n+2) {
        left: 60vw;
    }

    .descriptionClass {
        display: none;
    } */

}

.descname {
    text-align: left !important;
    font-family: 'Space Grotesk';
    color: #fff;
}

.descdesc {
    text-align: left !important;
}

.descbox {
    z-index: 100 !important;
}

.descriptionmobile {
    display: none;
    transition: 0.8s;
}

.transformstyle {
    transform-style: preserve-3d;
    z-index: 1;
}


@media (min-width:1024px) {
    .teams {
        flex-basis: 20%;
    }
}

@media (max-width:1024px) {
    .teams {
        flex-basis: 35%;
    }

    .descriptionmobile {
        display: none;
        background-color: rgb(79, 79, 79);
        opacity: 1;
    }

    .mobiledescodd {
        display: block !important;
        position: absolute !important;
        z-index: 1000 !important;
        right: 0rem;
    }

    .mobiledesceven {
        display: block !important;
        position: absolute !important;
        z-index: 1000 !important;
        left: 0rem;
        transform: translateZ(1000px)
    }

}