@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk&display=swap');

.desc {
    color: white;
    font-family: 'Space Grotesk';
    font-weight: 400;

}

.line {
    width: 30%;
    background-color: rgba(239, 101, 34, 1);
    border: 1px solid #EF6522;
    margin: auto;
}

.img {
    filter: drop-shadow(8px 8px 16px rgba(0, 0, 0, 0.25));
    margin-top: -60px;
}

.btn {
    background-color: #EF6522;
}

.gwrCardBg {
    background: linear-gradient(147.37deg,
            rgba(54, 54, 54, 0.89) -20.04%,
            rgba(0, 0, 0, 0.39) 135.5%);
}
